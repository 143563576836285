import { OIncidentsStatus } from '../types';
import type { Schema } from '@/types/mercury-data-types/unifiedapi';

export const useIssueState = (clientState?: Schema<'IssueClientResponse'>, isPending?: boolean) => {
  const status = clientState?.status ? clientState.status : OIncidentsStatus.New;
  const pending = isPending && status !== OIncidentsStatus.Resolved && OIncidentsStatus.Pending;
  const activities = clientState?.activities;
  const previousStatus = activities?.[activities.length - 2]?.type;
  const currentStatus = pending || status;

  return [currentStatus, previousStatus];
};
