import { Link } from 'react-router-dom';

import Coin from './Coin';

import { LOW_QUOINS } from '../../../../constants';
import { useAuth } from '@/hooks/useAuth';

const CoinMenuItem = () => {
  const { userInfo: { quoins } } = useAuth();

  return (
    <Link to="/settings">
      <Coin value={quoins.available} lowValue={LOW_QUOINS} />
    </Link>
  );
};

export default CoinMenuItem;
