import { unifiedApiKeys } from '@/services/keys';
import { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';

export const SERVICEREQUESTS_ENDPOINTS = {
  rfi: '/issue/rfis/',
  support: '/issue/support/',
} as const;

export const rfiKeys = {
  all: ['rfi'] as const,

  table: () => [...rfiKeys.all, 'table'] as const,

  meta: () => [...rfiKeys.all, 'meta'] as const,

  gfx: () => [...rfiKeys.all, 'gfx'] as const,

  dashboard: (params?: Partial<UnifiedApiParams>) => (
    ['dashboard', unifiedApiKeys.records(SERVICEREQUESTS_ENDPOINTS.rfi, params)] as const
  ),
};
