import type { PropsWithChildren } from 'react';

import FullPageContent from '../box/FullPageContent';
import SupportForm from '@/client/features/mailboxes/SupportForm';

import Page, { type PageProps } from '@/components/layout/Page';
import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from '@/client/features/mailboxes/types';

interface RFIPageUpsellProps extends PageProps { }

const RFIPageUpsell = ({
  children,
  ...pageProps
}: PropsWithChildren<RFIPageUpsellProps>) => (
  <Page
    noAccessRender={(
      <FullPageContent>
        <SupportForm
          disabledFields={{ subject: true }}
          name="support-form"
          hideFields={{
            due_date: true,
          }}
          prefillData={{
            subject: SUPPORT_SUBJECTS.PREMIUM_TRIAL,
            description: 'I would like to request access to the premium module',
            due_date: new Date().toISOString().split('T')[0],
            to: SUPPORT_TYPES.SUPPORT,
          }}
          subjects={[SUPPORT_SUBJECTS.PREMIUM_TRIAL]}
        />
      </FullPageContent>
    )}
    {...pageProps}
  >
    {children}
  </Page>
);

export default RFIPageUpsell;
