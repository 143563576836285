import ModuleWrapper from './ModuleWrapper';
import MercuryTabs from '@/components/content/tabs/Tabs';

import type { ModuleDef } from '../types/modulesv2';
import { useMediaQuery } from '@mantine/hooks';

interface IssueTabsProps<TData> {
  module: ModuleDef<TData>;
}

const IssueTabs = <TData extends unknown>({ module: { title, submodules, id, noPrint } }: IssueTabsProps<TData>) => {
  const matches = useMediaQuery('(min-width: 62em)');

  return (
    <ModuleWrapper title={title} noPrint={noPrint}>
      <MercuryTabs
        tabs={submodules.map((s) => ({ tab: s.title || '', val: s.title || '' }))}
        id={id}
        renderSelect={!matches}
        small
      >
        {submodules.map((submodule) => (submodule.render))}
      </MercuryTabs>
    </ModuleWrapper>
  );
};

export default IssueTabs;
