import { ActionIcon, Tooltip } from '@mantine/core';
import { type QueryKey } from '@tanstack/react-query';

import Button from '@/components/content/button/Button';

import { useQueryActions } from '@/hooks/useQueryActions';
import { useToast } from '@/hooks/useToast';
import { postMutation } from '@/lib/react-query/mutate';
import { useAuth } from '@/hooks/useAuth';

import AcknowledgeIcon from '@/assets/icons/content/acknowledge.svg';

interface AcknowledgeProps {
  issueId: number;
  isAcknowledged: boolean;
  resource: string;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: number;
  invalidate?: Array<QueryKey>;
  previousStatus: string;
}

const Acknowledge = ({
  issueId,
  isAcknowledged,
  asButton,
  loading,
  disabled,
  size = 20,
  resource,
  invalidate = [],
  previousStatus,
}: AcknowledgeProps) => {
  const actions = useQueryActions();
  const auth = useAuth();

  const mutation = postMutation({
    endpoint: `v0${resource}${issueId}`,
    method: 'PATCH',
  });
  const { createToast } = useToast();

  const disableAcknowledge = disabled || loading || mutation.isPending;
  const isLoading = loading || mutation.isPending;
  const text = isAcknowledged ? 'Unacknowledge' : 'Acknowledge';

  const onAcknowledge = () => {
    mutation.mutate({
      client_state: [
        {
          client_id: auth.userInfo.client_access_id,
          status: isAcknowledged ? previousStatus : 'acknowledged',
        },
      ],
    }, {
      onSuccess: () => {
        // Replication is async and takes some time to complete
        setTimeout(() => {
          actions.invalidateQueries(invalidate, { type: 'all' });
        }, 500);
      },
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return (
    <>
      {asButton ? (
        <Button
          onClick={onAcknowledge}
          color="var(--quo-green)"
          size="sm"
          loading={isLoading}
          disabled={disableAcknowledge}
          rightSection={<AcknowledgeIcon width={15} height={15} />}
        >
          {text}
        </Button>
      ) : (
        <Tooltip label={text}>
          <ActionIcon
            size={size}
            variant="subtle"
            aria-label={text}
            disabled={disableAcknowledge}
            color={isAcknowledged ? 'var(--quo-green-50)' : ''}
            loaderProps={{ size: size - 4 }}
            loading={isLoading}
            onClick={onAcknowledge}
          >
            <AcknowledgeIcon />
          </ActionIcon>
        </Tooltip>
      )}
    </>
  );
};

export default Acknowledge;
