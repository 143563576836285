import { unifiedApiKeys } from '@/services/keys';

import type { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';

export const INCIDENTS_ENDPOINTS = {
  incidents: '/issue/incidents/',
  takedowns: '/issue/takedowns/',
} as const;

export const incidentsKeys = {
  incidentsDashboard: (params?: Partial<UnifiedApiParams>) => (
    ['dashboard', ...unifiedApiKeys.records(INCIDENTS_ENDPOINTS.incidents, params)]
  ) as const,

  takedownsDashboard: (params?: Partial<UnifiedApiParams>) => (
    ['dashboard', ...unifiedApiKeys.records(INCIDENTS_ENDPOINTS.takedowns, params)]
  ) as const,

  incidentsTable: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records(INCIDENTS_ENDPOINTS.incidents, params)]
  ) as const,

  takedownsTable: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records(INCIDENTS_ENDPOINTS.takedowns, params)]
  ) as const,

  incidentsGfx: (params?: Partial<UnifiedApiParams>) => (
    ['gfx', ...unifiedApiKeys.records(INCIDENTS_ENDPOINTS.incidents, params)]
  ) as const,
};
