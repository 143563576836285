import { upperFirst } from '@mantine/hooks';

import Pill from '@/components/content/pills/Pill';

import { useIssueState } from '../hooks/useIssueState';
import { incidentsStatusColorCoding } from '../utils';

import type { Schema } from '@/types/mercury-data-types/unifiedapi';

interface IncidentStateProps {
  state?: Schema<'IssueClientResponse'>;
  hasTakedownRequests?: boolean;
}

const IncidentState = ({ state, hasTakedownRequests }: IncidentStateProps) => {
  const [currentStatus] = useIssueState(state, hasTakedownRequests);

  return (
    <Pill
      value={currentStatus ?? ''}
      component="span"
      color="var(--quo-white)"
      pillProps={incidentsStatusColorCoding}
      transform={(value) => upperFirst(value)}
      full
    />
  );
};

export default IncidentState;
