import FinishedSearchResults from '../../intelligence/components/FinishedSearchResults';
import RawSearchResults from '../../intelligence/components/RawSearchResults';
import SignalSearchResult from '../components/SignalSearchResult';
import IncidentSearchResult from '../../incidentmanagement/components/IncidentSearchResult';
import TakedownsSearchResult from '../../incidentmanagement/components/TakedownsSearchResult';
import ServiceRequestsSearchResult from '../../servicerequests/components/ServiceRequestsSearchResult';
import ThreatActorsSearchResult from '../../catalogs/features/threatactors/components/ThreatActorsSearchResult';
import MalwaresSearchResult from '../../catalogs/features/malwares/components/MalwaresSearchResult';
import ProvidersSearchResult from '../../catalogs/features/providers/components/ProvidersSearchResult';
import AttckSearchResult from '../../catalogs/features/attck/components/AttckSearchResult';
import SourcesSearchResult from '../../catalogs/features/sources/components/SourcesSearchResult';
// eslint-disable-next-line max-len
import VulnerabilitiesSearchResult from '../../catalogs/features/vulnerabilities/components/VulnerabilitiesSearchResult';
import PersonasSearchResult from '../../catalogs/features/personas/components/PersonasSearchResult';

import { INTEL_ENDPOINTS } from '../../intelligence/services/keys';
import { INCIDENTS_ENDPOINTS } from '../../incidentmanagement/services/keys';

import type { ResultsConfig } from '../types';
import { SERVICEREQUESTS_ENDPOINTS } from '../../servicerequests/services/keys';

/**
 * Add a filter to the URLSearchParams if a value is present
 */
const filterIf = (
  filters: Record<string, any>,
  builderObj: Record<string, (v: string) => string>,
) => Object.entries(builderObj).reduce((accu, curr) => {
  const [key, format] = curr;

  if (filters[key]) {
    const value = Array.isArray(filters[key]) ? filters[key].join(' ') : filters[key];

    accu.push(format(value));
  }

  return accu;
}, [] as Array<string>);

const issues: Array<ResultsConfig> = [
  {
    template: FinishedSearchResults,
    endpoint: INTEL_ENDPOINTS.finished,
    title: 'Finished Intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tab: 'finishedintel',
  },
  {
    template: RawSearchResults,
    endpoint: INTEL_ENDPOINTS.raw,
    title: 'Raw Intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tab: 'rawintel',
  },
  {
    template: IncidentSearchResult,
    endpoint: INCIDENTS_ENDPOINTS.incidents,
    title: 'Incidents',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'incidents',
    include: ['client_state.activities', 'rfis'],
  },
  {
    template: TakedownsSearchResult,
    endpoint: INCIDENTS_ENDPOINTS.takedowns,
    title: 'Takedowns',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'takedowns',
  },
  {
    template: ServiceRequestsSearchResult,
    endpoint: SERVICEREQUESTS_ENDPOINTS.rfi,
    title: 'RFIs',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'rfis',
  },
  {
    endpoint: '/signals',
    resultLimit: 10000,
    template: SignalSearchResult,
    title: 'Signals',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `@timestamp:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tab: 'signals',
  },
];

const catalogs: Array<ResultsConfig> = [
  {
    template: ThreatActorsSearchResult,
    endpoint: '/catalog/threat_actors/',
    title: 'Threat Actors',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `locations.id:${v} OR raw_intel:{locations.id:${v}}`,
    }),
    tab: 'threatactors',
  },
  {
    template: MalwaresSearchResult,
    endpoint: '/catalog/malware_kits/',
    title: 'Malware Kits',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'malwarekits',
  },
  {
    template: ProvidersSearchResult,
    endpoint: '/catalog/providers/',
    title: 'Providers',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => [
      ...filterIf(filters, {
        timeframe: (v) => `updated_at:${v}`,
        'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
        'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
      }),
      'description:*',
    ],
    tab: 'providers',
  },
  {
    template: AttckSearchResult,
    endpoint: '/catalog/attcks/',
    title: 'ATT&CKS',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'attcks',
  },
  {
    template: SourcesSearchResult,
    endpoint: '/catalog/sources/',
    title: 'Sources',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'sources',
  },
  {
    template: VulnerabilitiesSearchResult,
    endpoint: '/catalog/vulnerabilities/',
    title: 'Vulnerabilities',
    include: ['raw_intel', 'incidents'],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'vulnerabilities',
  },
  {
    template: PersonasSearchResult,
    endpoint: '/catalog/personas/',
    title: 'Personas',
    include: 'raw_intel',
    filterBuilder: (filters: Record<string, any>) => [
      ...filterIf(filters, {
        timeframe: (v) => `updated_at:${v}`,
        'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
        'locations.id': (v) => `locations.id:${v} OR raw_intel:{locations.id:${v}}`,
      }),
      'description:*',
    ],
    tab: 'personas',
  },
];

export const results = [
  ...issues,
  ...catalogs,
];
