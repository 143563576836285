import { Stack, Text } from '@mantine/core';

import { createHelper } from '@/client/features/issue/utils/helper';
import Markdown from '@/components/content/markdown/Markdown';
import PillsList from '@/components/content/pills/PillsList';
import List from '@/components/content/list/List';
import RiskGauge from '@/components/content/riskgauge/RiskGauge';
import RiskIndicators from '../../../../riskindicators/RiskIndicators';

import { formatIsoDate } from '@/utils/toDate';
import { getContextData } from '../../../utils/context';

import { aggregateFromMetisRelationship,
  aggregateFromMetisRelationshipWithAmount } from '../../../utils/aggregateFromMetisRelationship';
import { getValues } from '@/utils';

import type { MetisVulnerability } from '../types';
import { Includes } from '@/types/mercury-data-types/metis';
import { IncidentListRecord } from '@/client/features/incidentmanagement/types';

const helper = createHelper<Includes<MetisVulnerability, { incidents: Array<IncidentListRecord> }>>();

export const vulnerabilitiesDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Vulnerability</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        cvss3: data.cvss3,
        priority: data.priority,
      }), {
        element: ({ getValue }) => {
          const value = getValue();

          return (
            <RiskGauge risk={value.cvss3} priority={value.priority} />
          );
        },
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'locations')),
        technologies: getValues(aggregateFromMetisRelationshipWithAmount(
          [...data.raw_intel, ...data.incidents],
          'technologies',
        )),
        industry_sectors: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'industries')),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Vulnerabiliy Information',
    submodules: [
      helper.accessor((data) => data.name, {
        title: 'ID',
      }),
      helper.accessor((data) => data.disclosed_at, {
        title: 'Disclosed Date',
        element: ({ getValue }) => formatIsoDate(getValue(), true),
      }),
      helper.accessor((data) => data.type, {
        title: 'Vulnerability type',
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Last Updated',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => data.references, {
        title: 'References',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={(el: string) => (
              <Markdown text={el} renderExternalLinks />
            )}
          />
        ),
      }),
      helper.accessor((data) => data.poc_link, {
        title: 'PoC Link',
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => aggregateFromMetisRelationship(data.raw_intel, 'technologies'), {
        title: 'Technologies Affected',
        element: ({ getValue }) => (
          <PillsList
            max={5}
            pills={getValues(getValue())}
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data), {
        title: 'Context',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
        scroll: false,
      }),
    ],
  },
];
