import type { Includes } from '@/types/mercury-data-types/metis';
import type { Schema, UnifiedApiFetchResponse, UnifiedApiMethodsResponse } from '@/types/mercury-data-types/unifiedapi';
import type { BaseTicket, BaseTicketIncludes } from '../../issue/types/ticket';
import type { Overwrite } from '@/types/general';

export const OTakedownStatus = {
  Approved: 'Approved for Production',
  Feedback: 'Feedback',
  New: 'New',
  Pending: 'Pending',
  Resolved: 'Resolved',
  Rejected: 'Rejected',
  Closed: 'Closed',
  InProgress: 'In Progress',
} as const;

export type TakedownsListFetchResponse = UnifiedApiFetchResponse<'/issue/takedowns/', 'get'>;
export type TakedownsSummaryFetchResponse = UnifiedApiFetchResponse<'/issue/takedowns/summary', 'get'>;

/**
 * Overwrite keys that are not included in the request to avoid having to parse them later
 * If a request includes an attribute create a specific type using the Includes<> utilityu
 */
type TakedownTicketResponse = Overwrite<
UnifiedApiMethodsResponse<'/issue/takedowns/{resource_id}'>,
{
  incidents: { [key: string]: string };
}
>;

export type TakedownsTable = Overwrite<TakedownsListFetchResponse,
{ records: Array<TakedownListRecord>; count: number }
>;

export type TakedownListRecord = Includes<
TakedownTicketResponse,
{
  client_state: Array<Schema<'IssueClientResponse'>>;
}
>;

export type TakedownTicket = BaseTicket<Includes<TakedownTicketResponse, BaseTicketIncludes>>;
