import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Loading from '@/components/content/loading/Loading';
import ErrorWrapper from '@/components/content/error/ErrorWrapper';

import { openApiFetch } from '@/lib/openapi-fetch';
import { INTEL_ENDPOINTS } from '../services/keys';
import type { UnifiedApiPaths } from '@/types/mercury-data-types/unifiedapi';

const CommonIssue = () => {
  const { issue } = useParams();
  const issueId = +(issue as string);

  const baseQuery = (path: UnifiedApiPaths) => useQuery({
    queryKey: [path],
    queryFn: () => openApiFetch(
      path,
      {
        params: {
          path: { resource_id: issueId },
        },
      },

    ),
    gcTime: 0,
    staleTime: 0,
  });

  const finishedQuery = baseQuery(`${INTEL_ENDPOINTS.finished}{resource_id}`);
  const rawQuery = baseQuery(`${INTEL_ENDPOINTS.raw}{resource_id}`);

  return (
    <ErrorWrapper isError={rawQuery.isError && finishedQuery.isError}>
      <Loading
        text="Loading ticket"
        height="calc(100vh - calc(var(--app-shell-header-offset, 0rem) + var(--app-shell-padding)))"
      />

      {finishedQuery.isSuccess && (
        <Navigate to={`/intelligence/finished/issue/${issueId}`} />
      )}

      {rawQuery.isSuccess && (
        <Navigate to={`/intelligence/raw/issue/${issueId}`} />
      )}
    </ErrorWrapper>
  );
};

export default CommonIssue;
