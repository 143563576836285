import { MercuryTrackersAccesor } from '@/constants/mercuryPages';
import { unifiedApiKeys } from '@/services/keys';

import type { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';

export const INTEL_ENDPOINTS = {
  [MercuryTrackersAccesor.finished]: '/issue/finished_intel/',
  [MercuryTrackersAccesor.raw]: '/issue/raw_intel/',
} as const;

export const intelligenceKeys = {
  bulletin: (params?: Partial<UnifiedApiParams>) => (
    ['bulletin', ...unifiedApiKeys.records(INTEL_ENDPOINTS.finished, params)]
  ) as const,

  finishedDashboard: (params?: Partial<UnifiedApiParams>) => (
    ['dashboard', ...unifiedApiKeys.records(INTEL_ENDPOINTS.finished, params)]
  ) as const,

  rawDashboard: (params?: Partial<UnifiedApiParams>) => (
    ['dashboard', ...unifiedApiKeys.records(INTEL_ENDPOINTS.raw, params)]
  ) as const,

  finishedTable: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records(INTEL_ENDPOINTS.finished, params)]
  ) as const,

  rawTable: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records(INTEL_ENDPOINTS.raw, params)]
  ) as const,

  finishedVisualizations: () => (
    ['visualizations', ...unifiedApiKeys.records(INTEL_ENDPOINTS.finished)]
  ) as const,

  rawVisualizations: () => (
    ['visualizations', ...unifiedApiKeys.records(INTEL_ENDPOINTS.raw)]
  ) as const,
};
