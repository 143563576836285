/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip } from '@mantine/core';

import RedmineFieldList from '../catalogs/components/RedmineFieldList';
import DrawerLink from '../drawerlink/DrawerLink';
import DeferredDrawerLink from '../drawerlink/DeferredDrawerLink';
import SupportForm from '../mailboxes/SupportForm';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import { CATALOGS } from '@/constants/permissions';
import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from '../mailboxes/types';
import { unifiedApiKeys } from '@/services/keys';
import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';
import { openApiFetch } from '@/lib/openapi-fetch';

import type { MetisCatalogCommonFields, NormalizedCatalogListItem, NormalizedRedmineField } from '../catalogs/types';
import type { QuerySelect } from '@/types/react-query';
import type { Module } from '../issue/types/modulesv2';

interface CatalogLinkProps<TData, TSelect> {
  value: NormalizedRedmineField | NormalizedCatalogListItem | null;
  catalog: string;
  content: Array<Module<TSelect>>,
  select?: QuerySelect<TData, TSelect>,
  include?: Array<string>;
  link?: string;
}

const CatalogLink = <TData extends unknown, TSelectData extends MetisCatalogCommonFields>({
  name,
  disabled,
  id,
  catalog,
  content,
  select,
  link,
  include,
}: NormalizedCatalogListItem & Omit<CatalogLinkProps<TData, TSelectData>, 'value'>) => {
  const parsedCatalog = catalog.replace('_', '') as keyof typeof CATALOGS;
  const catalogPage = mercuryTrackers[MercuryTrackersIds[parsedCatalog]].page;
  const requiredModules = CATALOGS[parsedCatalog].modules;
  const { hasAccess } = useMercuryPermissions();
  const canAccess = hasAccess({ modules: requiredModules });
  const catalogId = +id;

  if (disabled) {
    return name;
  }

  if (!canAccess) {
    return (
      <Tooltip label="You need a premium subscription to see this content">
        <DrawerLink
          id={id}
          title="Request access to premium module"
          value={name}
          content={(
            <SupportForm
              disabledFields={{ subject: true }}
              name="support-form"
              prefillData={{
                subject: SUPPORT_SUBJECTS.PREMIUM_TRIAL,
                description: 'I would like to request access to the premium module',
                to: SUPPORT_TYPES.SUPPORT,
              }}
              subjects={[SUPPORT_SUBJECTS.PREMIUM_TRIAL]}
            />
          )}
        />
      </Tooltip>
    );
  }

  return (
    <DeferredDrawerLink
      value={name}
      query={{
        queryKey: unifiedApiKeys.ticket(`/catalog/${catalog}/{id}`, catalogId),
        queryFn: () => openApiFetch(
          `/catalog/${catalog}/{id}` as any,
          { params: { path: { id: catalogId }, query: { include: ['raw_intel', ...(include ?? [])] } } },
        ),
        select,
      }}
      link={`/catalogs/${link || catalogPage}/entity/${catalogId}`}
      content={content}
      id={catalogId}
    />
  );
};

const CatalogLinkList = <TData extends unknown, TSelectData extends MetisCatalogCommonFields>({
  value,
  ...rest
}: CatalogLinkProps<TData, TSelectData>) => {
  if (!value) return null;

  if (!Array.isArray(value)) {
    return <CatalogLink {...value} {...rest} />;
  }

  return (
    <RedmineFieldList
      items={value}
      renderItem={({ name, ...item }) => (
        <CatalogLink
          name={`${name} ${item.amount ? `(${item.amount})` : ''}`}
          {...item}
          {...rest}
        />
      )}
    />
  );
};

export default CatalogLinkList;
