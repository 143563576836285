import { Group, Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';

import BaseResult from '../../search/components/BaseResult';
import List from '@/components/content/list/List';
import Pill from '@/components/content/pills/Pill';
import IssueIDLinkv2 from '../../issueIdLink/IssueIdLink';

import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';
import { formatIsoDate } from '@/utils/toDate';
import { takedownsStatusColorCoding } from '../utils';
import { getKeys, objectHasValues } from '@/utils';

import type { TakedownListRecord } from '../types';

const TakedownsSearchResult = ({ data }: { data: TakedownListRecord }) => (
  <BaseResult
    title={{ id: data.id, name: data.name }}
    content={data.description}
    tracker={mercuryTrackers[MercuryTrackersIds.takedowns]}
    footer={[
      data.created_at && (
      <Text size="sm">
        <Text fw="bold" span>Date:</Text> {formatIsoDate(data.created_at)}
      </Text>
      ),
      <Text size="sm">
        <Text fw="bold" span>Status: </Text>
        <Pill
          value={upperFirst(data.status)}
          component="span"
          color="var(--quo-white)"
          pillProps={takedownsStatusColorCoding}
        />
      </Text>,
      objectHasValues(data.incidents)
      && (
        <Group gap={5}>
          <Text fw="bold" size="sm" span>Related Incidents:</Text>
          <List
            gap={5}
            direction="row"
            items={getKeys(data.incidents)}
            renderItem={(id) => (
              <IssueIDLinkv2
                value={+id}
                name={`${id}`}
                tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
                useParent
                usePage
              />
            )}
          />
        </Group>
      ),
    ]}
  />
);

export default TakedownsSearchResult;
