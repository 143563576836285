import BellIcon from '@/assets/icons/content/bell-filled.svg';

import classes from '../Notifications.module.css';

interface NotificationsBadgeIconProps {
  totalNotifications: number;
}

const NotificationsBadgeIcon = ({ totalNotifications }: NotificationsBadgeIconProps) => (
  <div className={classes.badgeContainer}>
    <BellIcon />
    {totalNotifications > 0 && (
      <span className={classes.badge}>{totalNotifications > 99 ? '+99' : totalNotifications}</span>
    )}
  </div>
);

export default NotificationsBadgeIcon;
