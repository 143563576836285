import { Stack, Text } from '@mantine/core';

import Markdown from '@/components/content/markdown/Markdown';
import IconMap from '../../iconmap/IconMap';
import List from '@/components/content/list/List';
import RiskIndicators from '../../riskindicators/RiskIndicators';

import { createHelper } from '../../issue/utils/helper';
import { formatIsoDate } from '@/utils/toDate';
import { CATEGORY_ICONS } from '../../iconmap/icons/intel_category';

import { FLAG_ICONS } from '../../iconmap/icons/flags';
import { parseMetisString } from '@/utils/metisUtils';
import { getContextData } from '../../catalogs/utils/context';
import { entriesToNormalizedRedmineArray } from '@/utils/normalizeRedmineArrayProp';
import { getEntries } from '@/utils';

import type { FinishedIntelTicket } from '../types';
import type { Module } from '../../issue/types/modulesv2';

const helper = createHelper<FinishedIntelTicket>();

export const finishedIntelDrawer: Array<Module<FinishedIntelTicket>> = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Finished Intelligence</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: entriesToNormalizedRedmineArray(data.locations),
        technologies: entriesToNormalizedRedmineArray(data.technologies),
        industry_sectors: entriesToNormalizedRedmineArray(data.industries),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => (
          <Markdown text={getValue()} textProps={{ lineClamp: 5 }} />
        ),
        scroll: false,
      }),
    ],
  },
  {
    direction: { base: 'column', s: 'row' },
    justify: 'space-around',
    gap: 20,
    submodules: [
      helper.accessor((data) => data.category, {
        title: 'Category',
        align: 'center',
        element: ({ getValue, id }) => (
          <IconMap
            id={id}
            icons={CATEGORY_ICONS}
            map={{ [getValue()]: getValue() }}
            showTooltip
          />
        ),
      }),
      helper.accessor((data) => (
        [
          ['analyst_note', data.analysts_note !== null],
          ['iocs', data.iocs !== null && data.iocs.length > 0],
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ].filter(([_, value]) => value)
      ), {
        title: 'Flags',
        align: 'center',
        element: ({ getValue, id }) => (
          <IconMap
            id={id}
            icons={FLAG_ICONS}
            map={Object.fromEntries(getValue().map(([key]) => [key, key]))}
            tooltip={(t) => `Contains ${parseMetisString(t)}`}
            showTooltip
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.created_at, {
        title: 'Source DTG',
        element: ({ getValue }) => formatIsoDate(getValue(), true),
      }),
      helper.accessor((data) => data.sources, {
        title: 'Source',
        element: ({ getValue }) => (
          getEntries(getValue()).map(([id, value]) => (
            <span key={id}>{value}</span>
          ))
        ),
        scroll: false,
      }),
      helper.accessor((data) => data.url, {
        title: 'Reference',
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data), {
        title: 'Context',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
      }),
    ],
  },
];
